import React, { FunctionComponent } from 'react';
import { ForgeSelect } from '@tylertech/forge-react';
import { IOption, ISelectOption } from '@tylertech/forge';
import I18n from 'common/i18n';
import { fetchAssetsByParameters } from '../store/AssetSlice';
import { updateSortByAndResetPageInUrl, getOrder } from '../store/UrlSlice';
import { useAppDispatch } from '../store/hooks';
import { useSelector } from 'react-redux';

const SortResults: FunctionComponent = () => {
  const scope = 'controls.browse.browse3';
  const sortByDefaultValue = useSelector(getOrder);
  const dispatch = useAppDispatch();

  const sortOptions: ISelectOption[] = [
    {
      label: I18n.t('sort.A_to_Z', { scope }),
      value: 'name',
      elementAttributes: new Map([['data-testId', 'sort-result-option-name']])
    },
    {
      label: I18n.t('sort.most_relevant', { scope }),
      value: 'relevance',
      elementAttributes: new Map([['data-testId', 'sort-result-option-relevance']])
    },
    {
      label: I18n.t('sort.most_viewed', { scope }),
      value: 'page_views_total',
      elementAttributes: new Map([['data-testId', 'sort-result-option-page-views-total']])
    },
    {
      label: I18n.t('sort.recently_added', { scope }),
      value: 'createdAt',
      elementAttributes: new Map([['data-testId', 'sort-result-option-created-at']])
    },
    {
      label: I18n.t('sort.recently_updated', { scope }),
      value: 'updatedAt',
      elementAttributes: new Map([['data-testId', 'sort-result-option-updated-at']])
    }
  ];
  const sortProps = {
    'data-testid': 'asset-results-sort-by-select',
    floatLabelType: 'always',
    label: I18n.t('sort.label', { scope }),
    onChange: (e: CustomEvent) => onChangeSorting(e.detail),
    options: sortOptions,
    value: sortByDefaultValue
  };

  const onChangeSorting = (order: string) => {
    dispatch(updateSortByAndResetPageInUrl(order));
    dispatch(fetchAssetsByParameters());
  };

  return <ForgeSelect {...sortProps} />;
};

export default SortResults;
